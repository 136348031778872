<svelte:options
  customElement={{
    tag: "oc-text-field-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
      formAssociated: true,
    }),
    props: {
      type: { type: "String" },
      placeholder: { type: "String" },
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" }, /*                                     */
      label: { type: "String" },
      hint: { type: "String" },
      pattern: { type: "String" },
      variant: { type: "String" },
      autocomplete: { type: "String" },
      required: { type: "Boolean" },
      minlength: { type: "Number" },
      maxlength: { type: "Number" },
      hideCounter: { type: "Boolean", attribute: "hide-counter" },
      prefixText: { type: "String", attribute: "prefix-text" },
      suffixText: { type: "String", attribute: "suffix-text" },
      prefixIcon: { type: "String", attribute: "prefix-icon" },
      suffixIcon: { type: "String", attribute: "suffix-icon" },
      passwordVisible: { type: "Boolean", attribute: "password-visible" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import HintV1 from "../../../common/components/HintV1.svelte";
  import CounterV1 from "../../../common/components/CounterV1.svelte";
  import { implicitSubmitHandler } from "../../../common/utils/form/implicitSubmitHandler";
  import { usePropertyChange } from "../../../common/utils/usePropertyChange";
  import type { Props } from "./TextFieldV1.types";

  usePropertyChange();

  export let name: Props["name"] = undefined;
  export let value: Exclude<Props["value"], undefined> = "";
  export let hint: Props["hint"] = undefined;
  export let variant: Props["variant"] = "default";
  export let required: Props["required"] = false;
  export let disabled: Props["disabled"] = false;
  export let pattern: Props["pattern"] = undefined;
  export let autocomplete: Props["autocomplete"] = "off";
  export let ocAriaLabel: Props["ocAriaLabel"] = undefined;

  export let placeholder: Props["placeholder"] = undefined;
  /*                                                                          */
  $: nonEmptyPlaceholder = placeholder || " ";

  /*                                                                                                         */
  export let type: Exclude<Props["type"], undefined> = "text";
  /*                             */
  $: nativeInputType = ["integer", "decimal"].includes(type) ? "number" : type;

  export let minlength: Props["minlength"] = undefined;
  export let maxlength: Props["maxlength"] = undefined;
  export let hideCounter: Props["hideCounter"] = false;
  export let prefixText: Props["prefixText"] = undefined;
  export let suffixText: Props["suffixText"] = undefined;
  export let prefixIcon: Props["prefixIcon"] = undefined;
  export let suffixIcon: Props["suffixIcon"] = undefined;
  export let passwordVisible: Props["passwordVisible"] = false;

  /*  */
  /*                                     */
  /*  */
  export const hintId = "hint";

  $: showCounter = (minlength ?? maxlength) !== undefined && !hideCounter;

  export let internals: ElementInternals;
  export let host: HTMLElement;

  export function resetForm() {
    /*                            */
    value = host.getAttribute("value") || "";
  }

  let inputElement: HTMLInputElement;

  $: if (inputElement) {
    internals.setFormValue(value);
    if (!internals?.form?.noValidate) {
      internals.setValidity(inputElement.validity, inputElement.validationMessage, inputElement);
    }
  }

  /*                                    */
  const onInput = () => {
    value = inputElement.value;
  };

  /*               */
  const focus = ({ target }: Event) => {
    if (target && "tagName" in target && target?.tagName !== "BUTTON") {
      inputElement?.focus();
    }
  };

  /*                                                     */
  const selectText = ({ target }: Event) => {
    if (target && "tagName" in target && target?.tagName !== "BUTTON") {
      inputElement?.select();
    }
  };

  /*                                          */
  const beforeInput = (event: InputEvent) => {
    if (event.data) {
      if (type === "decimal" && !/^[0-9.,]*$/.test(event.data)) {
        event.preventDefault();
      } else if (type === "integer" && !/^\d*$/.test(event.data)) {
        event.preventDefault();
      }
    }
  };

  const togglePasswordVisibility = (ev: Event) => {
    passwordVisible = !passwordVisible;
    ev.stopPropagation();
    ev.preventDefault();
  };
</script>

<div
  class="text-field"
  class:text-field--disabled={disabled}
  class:text-field--error={variant === "error"}
  class:text-field--has-value={value}
  class:text-field--success={variant === "success"}
  class:text-field--warning={variant === "warning"}
  on:click={focus}
  on:dblclick={selectText}
  role="none"
>
  <input
    {autocomplete}
    bind:this={inputElement}
    class="text-field__input"
    {disabled}
    id="input"
    {maxlength}
    {minlength}
    {name}
    on:beforeinput={beforeInput}
    on:input={onInput}
    on:keydown={implicitSubmitHandler(internals)}
    {pattern}
    placeholder={nonEmptyPlaceholder}
    {required}
    type={passwordVisible ? "text" : nativeInputType}
    {value}
    aria-describedby={hint ? "hint" : undefined}
    spellcheck="false"
    autocorrect="off"
  />

  {#if prefixIcon}
    <oc-icon-v1 class="text-field__prefix-icon" type={prefixIcon} />
  {/if}

  {#if prefixText}
    <small class="text-field__prefix-text">{prefixText}</small>
  {/if}

  {#if suffixText}
    <small class="text-field__suffix-text">{suffixText}</small>
  {/if}

  {#if suffixIcon}
    <oc-icon-v1 class="text-field__suffix-icon" type={suffixIcon} />
  {/if}

  {#if type === "password"}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <oc-link-v2
      class="text-field__password-toggle"
      variant="secondary"
      size={"75"}
      asButton={true}
      on:click={togglePasswordVisibility}
      ocAriaLabel={passwordVisible ? "Passwort ausblenden" : "Passwort anzeigen"}
      >{passwordVisible ? "Ausblenden" : "Anzeigen"}</oc-link-v2
    >
  {/if}
  <label class="text-field__label" for="input" aria-label={ocAriaLabel}>
    <slot />
  </label>
</div>

{#if hint || showCounter}
  <div class="text-field__hint-and-counter">
    {#if hint}
      <HintV1 id={hintId} {hint} {disabled} />
    {/if}
    {#if showCounter}
      <CounterV1 counterValue={value} maxCounter={maxlength} minCounter={minlength} {disabled} />
    {/if}
  </div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../../common/scss/mixins/mixins";

  $autofill-background-color: #f0f4fc;
  $transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  $gap: 8px;

  :host {
    @include mixins.no-tap-highlight();
  }

  .text-field {
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-areas: "prefix-icon prefix-text input suffix-text suffix-icon password-toggle";
    grid-template-columns: auto auto 1fr auto auto auto;
    align-items: center;

    padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;

    background: tokens.$oc-component-form-background-color;
    outline: 1px solid tokens.$oc-component-form-default-border-color;
    border-radius: tokens.$oc-component-form-field-border-radius;
    cursor: text;

    &:hover:not(.text-field--disabled) {
      outline-width: 2px;
    }

    &:focus-within {
      outline-width: 2px;

      &:not(.text-field--error):not(.text-field--warning):not(.text-field--success) {
        outline-color: tokens.$oc-component-form-default-border-color-focus;
      }
    }

    &__input {
      all: unset;
      grid-area: input;

      width: 100%;

      font: tokens.$oc-component-form-field-input-font;
      color: tokens.$oc-component-form-field-input-color;

      &::placeholder {
        font: tokens.$oc-component-form-field-placeholder-font;
        color: tokens.$oc-component-form-field-placeholder-color;
        opacity: 0;
        transition: $transition;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        box-sizing: border-box;
      }
    }

    &__label {
      position: absolute;
      top: tokens.$oc-component-form-field-spacing-y;
      left: tokens.$oc-semantic-spacing-50;
      padding: 0 tokens.$oc-semantic-spacing-25;
      max-width: calc(100% - 2 * tokens.$oc-semantic-spacing-50);
      box-sizing: border-box;

      transition: $transition;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      cursor: text;
      font: tokens.$oc-component-form-label-font;
      background-color: tokens.$oc-component-form-background-color;
      border-radius: tokens.$oc-component-form-field-label-border-radius;
      color: tokens.$oc-component-form-default-label-color;
    }

    &__hint-and-counter {
      display: grid;
      grid-column-gap: 16px;
      margin-top: tokens.$oc-component-form-hint-gap-y;
      width: 100%;

      > .counter {
        justify-self: end;
        grid-column-start: 2;
      }
    }

    /*                        */
    &__password-toggle {
      position: relative;

      grid-area: password-toggle;
      margin-left: $gap;

      & {
        @include mixins.focus-styles(tokens.$oc-semantic-focus-outline-radius-100);
      }
      /*                                                */
      &:before {
        content: "";
        position: absolute;
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        background-color: transparent;
      }
    }

    &--error {
      outline-color: tokens.$oc-component-form-error-border-color;

      .text-field__label {
        color: tokens.$oc-component-form-error-label-color;
      }
    }

    &--warning {
      outline-color: tokens.$oc-component-form-warning-border-color;
    }

    &--success {
      outline-color: tokens.$oc-component-form-success-border-color;

      .text-field__label {
        color: tokens.$oc-component-form-success-label-color;
      }
    }

    &--disabled {
      cursor: default;
      outline-color: tokens.$oc-component-form-disabled-border-color;
      background-color: tokens.$oc-component-form-disabled-background-color;

      .text-field__input {
        color: tokens.$oc-component-form-disabled-input-color;
      }

      .text-field__label {
        cursor: default;
        background-color: tokens.$oc-component-form-disabled-background-color;
        color: tokens.$oc-component-form-disabled-label-color;
      }
    }

    .text-field__prefix-icon {
      grid-area: prefix-icon;
    }

    .text-field__prefix-text {
      grid-area: prefix-text;
    }

    .text-field__suffix-icon {
      grid-area: suffix-icon;
    }

    .text-field__suffix-text {
      grid-area: suffix-text;
    }

    .text-field__prefix-text,
    .text-field__prefix-icon {
      transition: $transition;
      opacity: 0;
      margin-right: $gap;
    }

    .text-field__suffix-text,
    .text-field__suffix-icon {
      transition: $transition;
      opacity: 0;
      margin-left: $gap;
    }

    .text-field__prefix-text,
    .text-field__suffix-text {
      font: tokens.$oc-component-form-field-input-font;
      user-select: none;
    }

    /*                                          */

    @mixin enforceSeparateSelectors {
      &__input:autofill {
        @content;
      }
      &__input:-webkit-autofill {
        @content;
      }
      &:active:not(.text-field--disabled) .text-field__input {
        @content;
      }
      &:focus-within .text-field__input {
        @content;
      }
      &__input:not(:placeholder-shown) {
        @content;
      }
    }

    /**
 *
 *
 *
 */

    @include enforceSeparateSelectors {
      &::placeholder,
      ~ .text-field__suffix-icon,
      ~ .text-field__suffix-text,
      ~ .text-field__prefix-icon,
      ~ .text-field__prefix-text {
        opacity: 1;
      }

      ~ .text-field__label {
        top: -0.5rem;
        left: tokens.$oc-component-form-field-floating-label-spacing-left;
        padding: 0 tokens.$oc-component-form-field-floating-label-inner-spacing-x;
        font: tokens.$oc-component-form-field-floating-label-font;
      }
    }

    /*                           */
    &__input {
      &:autofill {
        -webkit-box-shadow: 0 0 0 50px $autofill-background-color inset;
        -webkit-text-fill-color: tokens.$oc-component-form-field-input-color;
      }
    }

    /*                                 */
    /*                                                                   */
    &__input {
      /*                             */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /*         */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    /*                                                                                 */
    &:has(.text-field__input:autofill) {
      background: $autofill-background-color;
    }
  }
</style>
